<template>
  <div>
    <top-nav/>
    
    <div class="terminalHeader">
      <div 
        v-for="(item,index) in navList" 
        :key="index" class="terminalnav" 
        :class="{active:activeindex == index}"
        @click="changeActiveTerminal(index)"
        >
        <img :src="item.imgSrc"/>
        <p>{{item.title}}</p>
      </div>
    </div>

    <div class="terminalBody">
      <div class="terminalBodya">
        <p>{{navList[activeindex].title}}</p>
        <h6>{{navList[activeindex].pro_tit}}</h6>
        <h5>{{navList[activeindex].pro_det}}</h5>
      </div>
      <p class="bodytitle">外观接口图例</p>
      <div class="detimg">
        <img :src="navList[activeindex].detimg1"/>
      </div>
      <p class="bodytitle">产品配置一览</p>
      <div class="detimg">
        <img :src="navList[activeindex].detimg2"/>
        <img :src="navList[activeindex].detimg3"/>
        <img :src="navList[activeindex].detimg4"/>
      </div>
    </div>

    <FooterMobile/>
  </div>
</template>

<script>
import FooterMobile from '../../../components/footerMobile.vue'
import topNav from '../../../components/topNav.vue'
import {getProductsShowList} from '@/api'
export default {
  components: { topNav, FooterMobile },
  data(){
    return{
      activeindex:0,
      activeTerminal:{},
      navListTemp:[],
      navList:[
          /* {
            imgSrc:require('@/assets/panshProduct/productionMobile/2711m.png'),
            title:'PS 2711+',
            pro_tit:'精益所致  磐石为开',
            pro_det:'精益所致、磐石为开，磐石系列云终端解决方案支持构建行业特色应用场景，采用全面固化基础架构设计，配置独立电源管理模块，提供丰富弹性扩展空间，部署深度优化操作系统，支持终端管理，支持可靠持久运行。磐石云终端，致力行业产能提升！',
            detimg1:require('@/assets/panshProduct/productionMobile/2711_1.png'),
            detimg2:require('@/assets/panshProduct/productionMobile/2711_4.png'),
            detimg3:require('@/assets/panshProduct/productionMobile/2711_2.png'),
            detimg4:require('@/assets/panshProduct/productionMobile/2711_3.png'),
          },
          {
            imgSrc:require('@/assets/panshProduct/productionMobile/8350m.png'),
            title:'PS 8350',
            pro_tit:'还淳返朴  磐石之安',
            pro_det:'还淳返朴，磐石之安，磐石系列云终端产品以其经典造型、多能配置、丰富扩展、全面固化、可靠保障等功能特性支持适用各应用场景，既可部署于工作组级应用，又能满足高覆盖企业级应用。',
            detimg1:require('@/assets/panshProduct/productionMobile/8350_1.jpg'),
            detimg2:require('@/assets/panshProduct/productionMobile/8350_2.jpg'),
            detimg3:require('@/assets/panshProduct/productionMobile/8350_3.jpg'),
            detimg4:require('@/assets/panshProduct/productionMobile/8350_4.jpg'),
          },
          {
            imgSrc:require('@/assets/panshProduct/productionMobile/1900m.png'),
            title:'PS 1900/1900+',
            pro_tit:'沉稳凝邃 安如磐石',
            pro_det:'沉稳凝邃 安如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            detimg1:require('@/assets/panshProduct/productionMobile/1900_1.jpg'),
            detimg2:require('@/assets/panshProduct/productionMobile/1900_2.jpg'),
            detimg3:require('@/assets/panshProduct/productionMobile/1900_3.jpg'),
            detimg4:require('@/assets/panshProduct/productionMobile/1900_4.jpg'),
          }, */
          {
            imgSrc:require('@/assets/panshProduct/productionMobile/4405m.png'),
            title:'PS 4405/4405+',
            pro_tit:'精致雕琢  坚如磐石',
            pro_det:'精致雕琢，坚如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            detimg1:require('@/assets/panshProduct/productionMobile/4405_1.jpg'),
            detimg2:require('@/assets/panshProduct/productionMobile/4405_2.jpg'),
            detimg3:require('@/assets/panshProduct/productionMobile/4405_3.jpg'),
            detimg4:require('@/assets/panshProduct/productionMobile/4405_4.png'),
          },
          {
            imgSrc:require('@/assets/panshProduct/productionMobile/7500m.png'),
            title:'PS 7500',
            pro_tit:'幻彩逸生  泰山磐石',
            pro_det:'幻彩逸生  泰山磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            detimg1:require('@/assets/panshProduct/productionMobile/7500_1.jpg'),
            detimg2:require('@/assets/panshProduct/productionMobile/7500_2.jpg'),
            detimg3:require('@/assets/panshProduct/productionMobile/7500_3.png'),
            detimg4:require('@/assets/panshProduct/productionMobile/7500_4.png'),
          },
          {
            imgSrc:require('@/assets/panshProduct/productionMobile/4405m.png'),
            title:'PS Z6540',
            pro_tit:'精致雕琢  坚如磐石',
            pro_det:'精致雕琢，坚如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            detimg1:require('@/assets/panshProduct/productionMobile/4405_1.jpg'),
            detimg2:require('@/assets/panshProduct/productionMobile/4405_2.jpg'),
            detimg3:require('@/assets/panshProduct/productionMobile/6540_3.png'),
            detimg4:require('@/assets/panshProduct/productionMobile/6540_4.png'),
          },
      ]
    }
  },
  methods:{
    // 切换展示终端
    changeActiveTerminal(index){
      this.activeindex = index
    },
    // 获取产品列表
    async getShowList(){
      const result = await getProductsShowList()
      result.data.filter((item,index) => {
        if(item.flag == 0){
          this.navList.push(this.navListTemp[index])
        }
      })
    }
  },
  created() {
    this.$nextTick(() => {
      this.activeindex = this.$route.query.active
    })
    this.$bus.$on('changeAct',this.changeActiveTerminal)
    // this.getShowList()
  }
}
</script>

<style scoped>
  .terminalHeader{display: flex;background: #ECECEC;padding-top: 17%;padding-bottom: 5%;width: 100%;overflow: auto;}
  .terminalnav{border-bottom: 3px solid #ECECEC;}
  .terminalnav img{height: 80px;width: auto;margin: 0 20px;}
  .terminalnav p{font-size: 3.5vw;color: #333;text-align: center;}
  .active{border-bottom: 3px solid #FF3535;}
  .terminalBody{background: #F6F6F6;padding: 5vw 3vw;}
  .terminalBodya{padding: 3vw;margin: 0 auto;background: #fff;}
  .terminalBodya p{font-size: 4.6vw;color: #333;font-weight: bold;}
  .terminalBodya h6{font-size: 4.6vw;color: #333;margin: 2vw 0;}
  .terminalBodya h5{font-size: 3.8vw;color: #666;font-weight: normal;}
  .bodytitle{font-size: 4.6vw;color: #333;margin: 3vw 0;}
  .detimg img{width: 100%;}
</style>